<template>
  <div class="merchantcenter_page">
    <div
      style="
        box-shadow: 10px 10px 10px rgb(0 0 0 / 35%);
        background: #fff;
        padding: 30px;
        height: 100%;
        box-sizing: border-box;
      "
    >
      <div class="page_title">{{ language.Administrator }}</div>
      <!-- 添加商户 -->
      <div class="addbox">
        <el-button style="width: 100px" type="primary" @click="add()"
          >+{{ language.addto }}</el-button
        >
      </div>
      <div>
        <!-- 商户列表 -->
        <div style="margin-top: 30px">
          <el-table
            v-loading="loading"
            :data="tableData"
            border
            style="width: 100%"
            stripe
            :header-cell-style="{ background: '#fafafa' }"
          >
            <!-- ID -->
            <el-table-column prop="id" label="ID" min-width="10%"> </el-table-column>
            <!-- 账号 -->
            <el-table-column
              prop="username"
              :label="language.accountnumber"
              min-width="10%"
            >
            </el-table-column>
            <!-- 昵称 -->
            <el-table-column prop="nickname" :label="language.nickname" min-width="10%">
            </el-table-column>
            <!-- 备注 -->
            <el-table-column prop="remark" :label="language.remarks" min-width="10%">
            </el-table-column>
            <!-- 创建时间 -->
            <el-table-column
              prop="jointime"
              :label="language.Creation_time"
              min-width="10%"
            >
            </el-table-column>
            <!-- 状态 -->
            <el-table-column prop="scene" :label="language.State" min-width="10%">
              <template slot-scope="scope">
                <el-switch
                  @change="stateChanged(scope.row)"
                  v-model="scope.row.status"
                  active-color="#13ce66"
                  active-value="1"
                  inactive-value="0"
                  inactive-color="#ff4949"
                >
                </el-switch>
              </template>
            </el-table-column>
            <el-table-column fixed="right" :label="language.Operation" min-width="15%">
              <template slot-scope="scope">
                <!-- 修改 -->
                <el-button
                  @click="handleClickchange(scope.row)"
                  type="success"
                  size="small"
                  >{{ language.Edit }}</el-button
                >
                <!-- 设置密码 -->
                <el-button @click="Settings(scope.row)" type="primary" size="small">{{
                  language.Password_Settings
                }}</el-button>
                <!-- 删除 -->
                <el-button @click="Deletebtn(scope.row)" type="danger" size="small">{{
                  language.Delete
                }}</el-button>
              </template>
            </el-table-column>
          </el-table>
        </div>
        <div>
          <div class="paging">
            <el-pagination
              v-loading="loading"
              @size-change="handleSizeChange"
              @current-change="handleCurrentChange"
              current-page.sync="5"
              :page-sizes="[10, 15, 20, 25, 30]"
              :page-size="limit"
              layout="sizes, prev, pager, next"
              :total="pages"
            >
            </el-pagination>
          </div>
        </div>
      </div>
    </div>
    <!-- 密码设置 -->
    <el-dialog
      :close-on-click-modal="false"
      :title="title"
      :visible.sync="View_detailsshow2"
      width="30%"
      center
    >
      <div
        style="
          display: flex;
          flex-direction: column;
          align-items: center;
          padding-bottom: 40px;
        "
      >
        <div style="width: 100%">
          <!-- 设置密码 -->
          <div style="margin-top: 20px; margin-right: 5%; width: 100%">
            <div style="height: 40px; line-height: 40px; white-space: nowrap">
              {{ language.password }}:
            </div>
            <el-input style="border: none" v-model="password" placeholder=""></el-input>
          </div>
        </div>
        <!-- 确定取消 -->
        <div style="margin-top: 50px">
          <el-button style="" @click="Cancel()">{{ language.Cancel }}</el-button>
          <el-button style="" type="primary" @click="changepasswordtrue()">{{
            language.determine
          }}</el-button>
        </div>
      </div>
    </el-dialog>
    <!-- 信息编辑及添加 -->
    <el-dialog
      :close-on-click-modal="false"
      :title="title"
      :visible.sync="View_detailsshow"
      width="30%"
      center
      v-loading="loading2"
    >
      <div
        style="
          display: flex;
          flex-direction: column;
          align-items: center;
          padding-bottom: 40px;
        "
      >
        <div style="width: 100%">
          <!-- 账号 -->
          <div style="margin-top: 20px; margin-right: 5%; width: 100%">
            <div style="height: 40px; line-height: 40px; white-space: nowrap">
              {{ language.accountnumber }}:
            </div>
            <el-input
              :disabled="btntype == 2"
              style="border: none"
              v-model="accountnumber"
              placeholder=""
            ></el-input>
          </div>
          <!-- 密码 -->
          <div
            v-if="btntype == 1"
            style="margin-top: 20px; margin-right: 5%; width: 100%"
          >
            <div style="height: 40px; line-height: 40px; white-space: nowrap">
              {{ language.password }}:
            </div>
            <el-input style="border: none" v-model="password" placeholder=""></el-input>
          </div>
          <!-- 昵称 nickname -->
          <div style="margin-top: 20px; margin-right: 5%; width: 100%">
            <div style="height: 40px; line-height: 40px; white-space: nowrap">
              {{ language.nickname }}:
            </div>
            <el-input style="border: none" v-model="nickname" placeholder=""></el-input>
          </div>
          <!-- 角色权限 RolePermission -->
          <div style="margin-top: 20px; margin-right: 5%; width: 100%">
            <div style="height: 40px; line-height: 40px; white-space: nowrap">
              {{ language.RolePermission }}:
            </div>
            <el-radio
              :disabled="item.status == 'hidden'"
              v-for="(item, index) in grouplist"
              :key="index"
              v-model="checkList"
              :label="item.id"
              >{{ item.name }}</el-radio
            >
            <!-- <el-checkbox-group v-model="checkList">
              <el-checkbox
                v-for="(item, index) in grouplist"
                :key="index"
                :label="item.id"
                >{{ item.name }}</el-checkbox
              >
            </el-checkbox-group> -->
          </div>
          <!-- 备注 -->
          <div style="margin-top: 20px; margin-right: 5%; width: 100%">
            <div style="height: 40px; line-height: 40px; white-space: nowrap">
              {{ language.remarks }}:
            </div>
            <el-input
              type="textarea"
              style="border: none"
              v-model="remarks"
              :autosize="{ minRows: 4, maxRows: 4 }"
              placeholder=""
            ></el-input>
          </div>
        </div>
        <div style="margin-top: 50px">
          <el-button style="" @click="Cancel()">{{ language.Cancel }}</el-button>
          <el-button v-if="btntype == 1" style="" type="primary" @click="determine()">{{
            language.determine
          }}</el-button>
          <el-button
            v-if="btntype == 2"
            style=""
            type="primary"
            @click="handleClickchangetrue()"
            >{{ language.determine }}</el-button
          >
        </div>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import {
  usersubList,
  group,
  usersubRegister,
  usersubUpdate,
  usersubDelete,
  usersubModify,
} from "../../../api/index";
export default {
  data() {
    return {
      id: "",
      btntype: "",
      title: "",
      languagetype: "",
      language: "",
      tableData: [],
      loading: false,
      loading2: false,
      page: 1, //页数
      limit: 15,
      pages: 0,
      View_detailsshow: false,
      View_detailsshow2: false,
      password: "",
      nickname: "",
      grouplist: [],
      remarks: "",
      accountnumber: "", //账号
      checkList: "", //权限
    };
  },
  created() {
    // 判断语言
    this.changelanguage();
    this.usersubList();
    this.group();
  },
  watch: {
    // 判断语言
    "$store.state.language"() {
      this.changelanguage();
    },
  },

  methods: {
    // 修改子账号状态
    stateChanged(e) {
      let data = {
        id: e.id,
        field: "status",
        value: e.status,
      };
      usersubModify(data).then(() => {
        this.$message({
          message: this.language.Edit_succeeded,
          type: "success",
        });
      });
    },
    // 修改密码
    changepasswordtrue() {
      // 判断昵称
      if (!this.password) {
        this.$message({
          message: this.language.password + this.language.cannotbeempty,
          type: "warning",
        });
        return;
      }
      let data = {
        username: this.accountnumber,
        nickname: this.nickname,
        groupId: this.checkList,
        remark: this.remarks,
        password: this.password,
      };
      usersubUpdate(data, { id: this.id }).then(() => {
        this.View_detailsshow2 = false;
        this.$message({
          message: this.language.Edit_succeeded,
          type: "success",
        });
        this.usersubList();
      });
    },
    // 取消按钮
    Cancel() {
      this.View_detailsshow2 = false;
      this.View_detailsshow = false;
    },
    // 设置密码
    Settings(e) {
      this.password = "";
      this.id = e.id;
      this.accountnumber = e.username;
      this.nickname = e.nickname;
      this.remarks = e.remark;
      this.checkList = e.group_id;
      this.title = this.language.Password_Settings;
      this.View_detailsshow2 = true;
    },
    // 确定编辑
    handleClickchangetrue() {
      // 判断账号
      if (!this.accountnumber) {
        this.$message({
          message: this.language.accountnumber + this.language.cannotbeempty,
          type: "warning",
        });
        return;
      }
      // 判断昵称
      if (!this.nickname) {
        this.$message({
          message: this.language.nickname + this.language.cannotbeempty,
          type: "warning",
        });
        return;
      }
      // 判断身份
      if (!this.checkList) {
        this.$message({
          message: this.language.RolePermission + this.language.cannotbeempty,
          type: "warning",
        });
        return;
      }
      let data = {
        username: this.accountnumber,
        nickname: this.nickname,
        groupId: this.checkList,
        remark: this.remarks,
      };
      usersubUpdate(data, { id: this.id }).then(() => {
        this.View_detailsshow = false;
        this.$message({
          message: this.language.Edit_succeeded,
          type: "success",
        });
        this.usersubList();
      });
    },
    // 确定添加
    determine() {
      // 判断账号
      if (!this.accountnumber) {
        this.$message({
          message: this.language.accountnumber + this.language.cannotbeempty,
          type: "warning",
        });
        return;
      }
      // 判断密码
      if (!this.password) {
        this.$message({
          message: this.language.password + this.language.cannotbeempty,
          type: "warning",
        });
        return;
      }
      // 判断昵称
      if (!this.nickname) {
        this.$message({
          message: this.language.nickname + this.language.cannotbeempty,
          type: "warning",
        });
        return;
      }
      // 判断身份
      if (!this.checkList) {
        this.$message({
          message: this.language.RolePermission + this.language.cannotbeempty,
          type: "warning",
        });
        return;
      }
      let data = {
        username:
          JSON.parse(localStorage.getItem("userinfo")).username +
          "#" +
          this.accountnumber,
        password: this.password,
        nickname: this.nickname,
        groupId: this.checkList,
        remark: this.remarks,
      };
      usersubRegister(data).then(() => {
        this.View_detailsshow = false;
        this.$message({
          message: this.language.Edit_succeeded,
          type: "success",
        });
        this.usersubList();
      });
    },
    // 获取角色列表
    group() {
      let params = {
        page: this.page,
        limit: this.limit,
      };
      group(params).then((res) => {
        this.grouplist = res.data.list;
      });
    },
    // 获取子账号列表
    usersubList() {
      this.loading = true;
      let params = {
        page: this.page,
        limit: this.limit,
      };
      usersubList({}, params).then((res) => {
        this.loading = false;
        this.tableData = res.data.list;
        this.loading = false;
        this.tableData.map((item) => {
          item.status = String(item.status);
        });
        this.pages = res.data.count;
      });
    },
    // 删除
    Deletebtn(e) {
      this.id = e.id;
      this.$confirm(this.language.confirmdeletion, this.language.prompt, {
        confirmButtonText: this.language.determine,
        cancelButtonText: this.language.cancel,
        type: "warning",
      })
        .then(() => {
          let params = {
            id: this.id,
          };
          usersubDelete({}, params).then((res) => {
            if (res.code == 1) {
              this.$message({
                type: "success",
                message: this.language.Edit_succeeded + "!",
              });
              this.usersubList();
            }
          });
        })
        .catch(() => {
          this.$message({
            type: "info",
            message: this.language.Cancelleddeletion,
          });
        });
    },
    // 添加按钮
    add() {
      this.btntype = 1;
      this.accountnumber = "";
      this.nickname = "";
      this.remarks = "";
      this.checkList = "";
      this.password = "";
      this.title = this.language.addto; //弹窗顶部
      this.View_detailsshow = true;
    },
    // 编辑
    handleClickchange(e) {
      this.id = e.id;
      this.accountnumber = e.username;
      this.nickname = e.nickname;
      this.remarks = e.remark;
      this.checkList = e.group_id;
      this.btntype = 2;
      this.title = this.language.Edit; //弹窗顶部
      this.View_detailsshow = true;
    },
    // 切换每页条数
    handleSizeChange(val) {
      this.page = 1;
      this.limit = val;
      this.usersubList();
    },
    // 选择页数
    handleCurrentChange(val) {
      this.page = val;
      this.usersubList();
    },
    // 获取语言
    changelanguage() {
      this.languagetype = this.$store.state.language;
      this.language = this.$units();
    },
  },
};
</script>
<style scoped>
.merchantcenter_page {
  height: 100%;
  padding: 20px;
  box-sizing: border-box;
}
.page_title {
  font-size: 26px;
  font-weight: 600;
}
.addbox {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  border-bottom: 1px dashed rgb(203, 203, 203);
  padding-bottom: 4px;
}
.paging {
  width: 100%;
  display: flex;
  margin-top: 30px;
  justify-content: flex-end;
}

* /deep/ .el-input .el-input__inner {
  padding: 0 4px !important;
}

* /deep/ .el-table .cell {
  white-space: nowrap;
}
</style>
